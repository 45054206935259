import LoginForm from '../components/LoginForm'


function MainLayout(){

	return (
		<div className='main-layout'>
			<div className='main-layout-bg' />
			<div className='main-layout-form'>
				<div className='title'>
					<div>Billing</div>
					<div>CRM</div>
				</div>
				<LoginForm />
			</div>
		</div>
	)

}
export default MainLayout