import { useId, useState, useCallback } from 'react'

import { ReactComponent as EyeSlashSvg } from '../icons/EyeSlash.svg'
import { ReactComponent as EyeSvg } from '../icons/Eye.svg'


function LoginForm(){

	const loginId = useId()

	const passwordId = useId()
	const [passwordShowed, setPasswordShowed] = useState(false)
	const showPasswordHandler = useCallback(e => {
		e.preventDefault()
		if(passwordShowed) setPasswordShowed(false)
		else setPasswordShowed(true)
	}, [passwordShowed])


	return (
		<form className='auth-form'>
			<div className='m24 auth-form-title'>Sign in to Biling CRM account</div>
			<div className='field'>
				<label htmlFor={loginId} className='field-label'>Login</label>
				<input type='text' id={loginId} placeholder='Enter your login' autoComplete='off' />
			</div>
			<div className='field field-ico-right'>
				<label htmlFor={passwordId} className='field-label'>Password</label>
				<div className='field-container'>
					<input type={passwordShowed ? 'text' : 'password'} id={passwordId} placeholder='Enter your password' autoComplete='off' />
					{passwordShowed ? <EyeSlashSvg className='field-svg-right' onClick={showPasswordHandler} /> : <EyeSvg className='field-svg-right' onClick={showPasswordHandler} />}
				</div>
			</div>
			<button className='btn white-green'>Login</button>
		</form>
	)

}
export default LoginForm